.logo {
  padding: 32px; }

.list {
  padding-top: 32px; }

.header-list {
  list-style: none;
  float: right; }

.header-list-item {
  display: inline-block;
  margin: 5px 0px;
  padding: 0px 5px;
  border-right: 1px solid #D1D1D1;
  color: #22588D;
  cursor: pointer; }

.header-list-item:hover {
  border-bottom: none !important; }

.header-list-item.last {
  border-right: none; }

i {
  color: #22588D; }

/*# sourceMappingURL=../../../../../../../static/hrs_pp/css/static_header.css.map */